exports.components = {
  "component---one-page-checkout-src-pages-checkout-index-tsx": () => import("./../../../../one-page-checkout/src/pages/checkout/index.tsx" /* webpackChunkName: "component---one-page-checkout-src-pages-checkout-index-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-curadoria-js": () => import("./../../../src/pages/curadoria.js" /* webpackChunkName: "component---src-pages-curadoria-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ineditos-js": () => import("./../../../src/pages/ineditos.js" /* webpackChunkName: "component---src-pages-ineditos-js" */),
  "component---src-pages-kits-passados-curadoria-index-tsx": () => import("./../../../src/pages/kits-passados/curadoria/index.tsx" /* webpackChunkName: "component---src-pages-kits-passados-curadoria-index-tsx" */),
  "component---src-pages-kits-passados-index-tsx": () => import("./../../../src/pages/kits-passados/index.tsx" /* webpackChunkName: "component---src-pages-kits-passados-index-tsx" */),
  "component---src-pages-kits-passados-ineditos-index-tsx": () => import("./../../../src/pages/kits-passados/ineditos/index.tsx" /* webpackChunkName: "component---src-pages-kits-passados-ineditos-index-tsx" */),
  "component---src-templates-previous-kits-book-tsx": () => import("./../../../src/templates/previousKits-book.tsx" /* webpackChunkName: "component---src-templates-previous-kits-book-tsx" */)
}

