import React, { createContext, useContext, useMemo, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import slugify from '../utils/slugify'

const CategoryContext = createContext()

const CategoryProvider = ( props ) => {
  const { 'apollo': { 'categorias': { categorias } } } = useStaticQuery( graphql`
    query {
      apollo {
        categorias(id: [1, 3, 4, 6]) {
          categorias {
            id
            nome
            planos {
              id
              valor_total
              valor_por_kit
              tipo_plano
              categoria
            }
          }
        }
      }
    }
  ` )

  categorias[ 0 ].planos.sort( ( plan ) => plan.tipo_plano === 'Anual' ? -1 : +1 )
  categorias[ 1 ].planos.sort( ( plan ) => plan.tipo_plano === 'Anual' ? -1 : +1 )

  const getPlans = () => {
    return categorias.map( ( { 'planos': plans } ) => {
      const amount = plans[ 1 ].valor_por_kit * 12
      const valueSaved = amount - plans[ 0 ].valor_total

      return [ { ...plans[ 0 ], 'valorEconomizado': valueSaved }, { ...plans[ 1 ] } ]
    } )
  }

  const [ state, setState ] = useState( {
    'activeCategory': categorias[ 1 ],
    'activeCategoryId': categorias[ 1 ].id,
    'activePlan': categorias[ 1 ].planos[ 0 ],
    'activePlanId': categorias[ 1 ].planos[ 0 ].id,
    'categories': [ categorias[ 1 ], categorias[ 0 ] ],
    'plans': getPlans(),
  } )

  const value = useMemo( () => {
    return {
      setState,
      state
    }
  }, [ state ] )

  return (
    <CategoryContext.Provider
      value={ value }
      { ...props }
    />
  )
}

const useCategory = () => {
  const context = useContext( CategoryContext )
  if ( !context ) {
    throw new Error( 'useCategory should have be inside a CategoryProvider' )
  }

  const { state, setState } = context

  const setActivePlanId = newActivePlanId => {
    setState( prevState => ( {
      ...prevState,
      'activePlanId': newActivePlanId
    } ) )
  }

  const setActivePlan = activePlan => {
    setState( prevState => ( {
      ...prevState,
      activePlan
    } ) )
  }

  const setActiveCategoryId = newActiveCategoryId => {
    if ( newActiveCategoryId !== state.activeCategoryId ) {
      setState( prevState => ( {
        ...prevState,
        'activeCategoryId': newActiveCategoryId
      } ) )

      switch ( state.activePlanId ) {
        case 1:
          setActivePlanId( 4 )
          break
        case 3:
          setActivePlanId( 6 )
          break
        case 4:
          setActivePlanId( 1 )
          break
        case 6:
          setActivePlanId( 3 )
          break
        default:
          break
      }
    }
  }

  const setActiveCategory = activeCategory => {
    setState( prevState => ( {
      ...prevState,
      activeCategory
    } ) )
  }

  const findCategoryByName = ( categoryToCompare ) => {
    return state.categories
      .find( category => ( slugify( category.nome ).toLowerCase() === categoryToCompare ) )
  }

  const findCategoryById = ( id ) => {
    return state.categories
      .find( category => ( category.id === id ) )
  }

  const findPlan = ( category, planToCompare ) => {
    return category.planos.find( plan => plan.tipo_plano.toLowerCase() === planToCompare )
  }

  const findPlanById = ( category, planId ) => {
    return category.planos.find( plan => plan.id === planId )
  }

  const setContextByQueryParams = ( { categoria, modalidade }, setDisplayPageContent ) => {
    if ( categoria ) {
      const selectedCategory = findCategoryByName( categoria )

      if ( selectedCategory ) {
        setActiveCategoryId( selectedCategory.id )
        setActiveCategory( selectedCategory )

        if ( modalidade ) {
          const selectedPlan = findPlan( selectedCategory, modalidade )

          if ( selectedPlan ) {
            setActivePlanId( selectedPlan.id )
            setActivePlan( selectedPlan )
            return
          }
        }
      }
    }

    setDisplayPageContent( true )
  }

  return {
    'activeCategory': state.activeCategory,
    'activeCategoryId': state.activeCategoryId,
    'activePlan': state.activePlan,
    'activePlanId': state.activePlanId,
    'categories': state.categories,
    findCategoryById,
    findCategoryByName,
    findPlan,
    findPlanById,
    'plans': state.plans,
    setActiveCategory,
    setActiveCategoryId,
    setActivePlan,
    setActivePlanId,
    setContextByQueryParams,
  }
}

export { CategoryProvider, useCategory }
