/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState, ReactElement, createContext } from 'react'
import { BookFilter } from '@shared/interfaces/BookFilter'

export const BookFilterContext = createContext<BookFilter.Props>( {
  'saveYears': () => {},
  'toggleActiveYears': () => {},
  'years': [],
} )

const BookFilterProvider = ( { children }: BookFilter.Props ): ReactElement => {
  const [ years, setYears ] = useState<BookFilter.Year[]>( [] )

  const currentTime = new Date()
  const currentYear = currentTime.getFullYear()
  const lastYear = currentYear - 1

  const yearsActiveDefault = [ currentYear.toString(), lastYear.toString() ]

  const saveYears = ( yearsSave: string[], activeYears: string[] ) => {
    setYears( () => {
      const mappedYears = yearsSave.map( year => {
        return {
          'value': year,
          'active': yearsActiveDefault.includes( year ) || activeYears.includes( year )
        }
      } )
      return mappedYears
    } )
  }

  const toggleActiveYears = ( ...yearsUpdate: string[] ) => {
    const mappedYears = years.map( ( year: BookFilter.Year ) => {
      if ( yearsUpdate.includes( year.value ) ) {
        return {
          'active': !year.active,
          'value': year.value
        }
      } else {
        return year
      }
    } )

    setYears( mappedYears )
  }

  return (
    <BookFilterContext.Provider value={{ years, saveYears, toggleActiveYears }}>
      {children}
    </BookFilterContext.Provider>
  )
}

export default BookFilterProvider
