/* eslint-disable no-unused-vars */
/* eslint-disable require-unicode-regexp */
/* eslint-disable guard-for-in */
const slugify = ( str ) => {
  const map = {
    '-': '_',
    'a': 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
    'c': 'ç|Ç',
    'e': 'é|è|ê|ë|É|È|Ê|Ë',
    'i': 'í|ì|î|ï|Í|Ì|Î|Ï',
    'n': 'ñ|Ñ',
    'o': 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
    'u': 'ú|ù|û|ü|Ú|Ù|Û|Ü',
  }

  let parsed = str

  for ( const pattern in map ) {
    parsed = parsed.replace( new RegExp( map[ pattern ], 'g' ), pattern )
  }


  return parsed
}

export { slugify }
export default slugify
