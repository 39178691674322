import React, { createContext, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

const AddressContext = createContext()

const AddressProvider = ( props ) => {
  const {
    city = '',
    complement = '',
    district = '',
    ibgeCode = '',
    id = 0,
    number = '',
    shippingFee = 0,
    'state': stateInput = '',
    street = '',
    zipCode = '',
  } = props
  const [ state, setState ] = useState( {
    city,
    complement,
    district,
    ibgeCode,
    id,
    number,
    shippingFee,
    'state': stateInput,
    street,
    zipCode,
  } )

  const value = useMemo( () => {
    return {
      setState,
      state
    }
  }, [ state ] )

  return (
    <AddressContext.Provider
      value={ value }
      { ...props }
    />
  )
}


const useAddress = () => {
  const context = useContext( AddressContext )
  if ( !context ) {
    throw new Error( 'useAddress should have be inside a AddressProvider' )
  }

  const { state, setState } = context

  const setAddress = ( address ) => {
    setState( prevState => ( {
      ...prevState,
      'city': address.city,
      'complement': address.complement,
      'district': address.district,
      'ibgeCode': address.ibgeCode,
      'id': address.id,
      'number': address.number,
      'shippingFee': 'shippingFee' in address ? address.shippingFee : prevState.shippingFee,
      'state': address.state,
      'street': address.street,
      'zipCode': address.zipCode,
    } ) )
  }

  const getAddress = () => state

  return {
    'city': state.city,
    'complement': state.complement,
    'district': state.district,
    getAddress,
    'ibgeCode': state.ibgeCode,
    'id': state.id,
    'number': state.number,
    setAddress,
    'shippingFee': state.shippingFee,
    'state': state.state,
    'street': state.street,
    'zipCode': state.zipCode,
  }
}

AddressProvider.propTypes = {
  'city': PropTypes.string.isRequired,
  'complement': PropTypes.string.isRequired,
  'district': PropTypes.string.isRequired,
  'ibgeCode': PropTypes.string.isRequired,
  'id': PropTypes.number.isRequired,
  'number': PropTypes.string.isRequired,
  'shippingFee': PropTypes.number.isRequired,
  'state': PropTypes.string.isRequired,
  'street': PropTypes.string.isRequired,
  'zipCode': PropTypes.string.isRequired
}

export { AddressProvider, useAddress }
