import React, { createContext, useContext, useMemo, useState } from 'react'
import { usePayment } from './PaymentContext'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { trackBeginStage } from '@shared/services/tracking'
// TODO: ~30 arquivos de testes em JS falhando ao usar `enum`. Mudar para `enum` assim que os testes forem migrados para TS (e remover `typeof`s).
const Step = {
  'CHOOSE_BOX': 0,
  'PERSONAL': 1,
  'DELIVERY': 2,
  'PAYMENT': 3,
  'PIX': 4,
  'CONCLUSION': 5,
} as const

const MenuContext = (
  createContext<MenuContext.Props>( {} as any )
)
namespace MenuContext {
  export interface State {
    activeStep: typeof Step[keyof typeof Step]
    selectedIndex: typeof Step[keyof typeof Step]
    steps: Array<{
      completed: boolean
      href: string
      label: string
    }>
  }

  export interface Props {
    state: State
    setState: React.Dispatch<React.SetStateAction<State>>
  }
}

const MenuProvider = ( props: Partial<MenuContext.State> ) => {
  const {
    activeStep = Step.CHOOSE_BOX,
    selectedIndex = Step.CHOOSE_BOX,
    steps = [
      { 'completed': false, 'href': '/associe-se/escolha-sua-caixinha', 'label': 'Caixinha' },
      { 'completed': false, 'href': '/associe-se/identificacao/', 'label': 'Identificação' },
      { 'completed': false, 'href': '/associe-se/entrega', 'label': 'Entrega' },
      { 'completed': false, 'href': '/associe-se/pagamento', 'label': 'Pagamento' },
      { 'completed': false, 'href': '/associe-se/pix-confirmado', 'label': 'Pix confirmado' },
      { 'completed': false, 'href': '/associe-se/assinatura-concluida', 'label': 'Assinatura concluída' }
    ]
  } = props

  const [ state, setState ] = useState( {
    activeStep,
    selectedIndex,
    steps
  } )

  const value = useMemo( () => {
    return {
      setState,
      state
    }
  }, [ state ] )

  return (
    <MenuContext.Provider
      value={ value }
      { ...props }
    />
  )
}

const useMenu = () => {
  const context = useContext( MenuContext )
  if ( !context ) {
    throw new Error( 'useMenu should have be inside a MenuProvider' )
  }

  const { paymentMethod } = usePayment()
  const { state, setState } = context

  const goToNextStep = ( { activeStep = state.activeStep, queryParams = '' } = {} ) => {
    if ( state.activeStep < state.steps.length - 1 ) {
      const isToJumpTwoSteps = state.activeStep === Step.PAYMENT && paymentMethod === 'creditCard'
      const newactiveStep = state.activeStep + ( isToJumpTwoSteps ? 2 : 1 ) as typeof Step[keyof typeof Step]
      const newSteps = [ ...state.steps ]

      if ( activeStep === state.activeStep ) {
        newSteps[ state.activeStep ] = { ...newSteps[ state.activeStep ]!, 'completed': true }
        if ( isToJumpTwoSteps ) {
          newSteps[ state.activeStep + 1 ] = { ...newSteps[ state.activeStep + 1 ]!, 'completed': true }
        }
      }

      setState( {
        ...state,
        'activeStep': newactiveStep,
        'steps': newSteps
      } )

      if ( state.activeStep === Step.CHOOSE_BOX ) {
        trackBeginStage( 'checkout' )
      }

      navigate( newSteps[ newactiveStep ]!.href + queryParams )
    }
  }

  const goToPreviousStep = () => {
    if ( state.activeStep !== Step.CHOOSE_BOX ) {
      const newSteps = [ ...state.steps ]
      const newactiveStep = state.activeStep - 1 as typeof Step[keyof typeof Step]

      setState( {
        ...state,
        'activeStep': newactiveStep,
        'steps': newSteps
      } )

      navigate( newSteps[ newactiveStep ]!.href )
    }
  }

  const setSelectedIndex = ( selectedIndex: typeof Step[keyof typeof Step] ) => {
    setState( prevState => ( {
      ...prevState,
      selectedIndex
    } ) )
  }

  const setActiveStep = ( index: typeof Step[keyof typeof Step], { navigation = false } ) => {
    const { steps } = state

    if ( index >= Step.CHOOSE_BOX && index < steps.length ) {
      setState( prevState => ( {
        ...prevState,
        'activeStep': index,
        steps,
      } ) )

      if ( navigation ) {
        navigate( steps[ index ]!.href )
      }
    }
  }

  return {
    'activeStep': state.activeStep,
    goToNextStep,
    goToPreviousStep,
    'selectedIndex': state.selectedIndex,
    setActiveStep,
    setSelectedIndex,
    'steps': state.steps
  }
}

MenuProvider.propTypes = {
  'activeStep': PropTypes.number.isRequired,
  'selectedIndex': PropTypes.number.isRequired,
  'steps': PropTypes.array.isRequired
}

export { Step, MenuProvider, useMenu }
