import React, { createContext, useContext, useMemo, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const TagMonthContext = createContext()

const TagMonthProvider = ( props ) => {
  const { 'apollo': { tagMonths } } = useStaticQuery( graphql`
    query {
      apollo {
        tagMonths {
          categoria_id,
          display,
          mes_seguinte_display,
          data_fim,
          data_inicio,
          mes,
          ano
        }
      }
    }
  ` )

  const [ state, setState ] = useState( { tagMonths } )

  const value = useMemo( () => {
    return {
      setState,
      state
    }
  }, [ state ] )

  return (
    <TagMonthContext.Provider
      value={ value }
      { ...props }
    />
  )
}

const useTagMonth = () => {
  const context = useContext( TagMonthContext )
  if ( !context ) {
    throw new Error( 'useTagMonth should have be inside a TagMonthProvider' )
  }

  const { state } = context


  return {
    'tagMonths': state.tagMonths
  }
}

export { TagMonthProvider, useTagMonth }
