import { Category } from '@shared/interfaces/Category'
import { Coupon } from '@shared/interfaces/Coupon'
import { Plan } from '@shared/interfaces/Plan'

export function trackBeginStage( stage: 'checkout' | 'shipping_info' | 'payment_info' ): void {
  const eventCategory = 'Trilhas'
  const eventAction = `begin_${stage}`
  const eventLabel = 'TAG Coleção SCI-FI'

  track( {
    'event': 'ga_product_event',
    eventCategory,
    eventAction,
    eventLabel,
  } )

  if ( stage === 'checkout' ) {
    track( {
      'event': 'initiate_checkout',
    } )
  }
}

export function dispatchGlobalEvent( eventCategory: string, eventAction: string, eventLabel?: string | number ): void {
  const url = window.location.href

  track( {
    'event': 'ga_club_event',
    eventCategory,
    eventAction,
    'eventLabel': eventLabel || url,
  } )
}

export function dispatchPurchaseEvent( parameters: dispatchPurchaseEvent.Parameters ): void {
  const {
    transactionId,
    coupon,
    totalAmount,
    discount,
    category,
    plan,
  } = parameters

  track( {
    'event': 'club_purchase',
    'ecommerce': {
      transactionId,
      'value': totalAmount.toFixed( 2 ),
      'discountCode': coupon.code,
      'discountAmount': discount,
      'items': [
        {
          'index': 0,
          'itemId': plan.id,
          'itemName': `${category.nome} - ${plan.tipo_plano}`,
          'category': category.nome,
          'categoryId': category.id,
          'quantity': 1,
          'price': plan.valor_total,
        }
      ],
    }
  } )
}
export namespace dispatchPurchaseEvent {
  export interface Parameters {
    transactionId: number
    coupon: Coupon
    totalAmount: number
    discount: number
    category: Category
    plan: Plan
  }
}

export function dispatchNotEnoughBalanceEvent(): void {
  track( { 'event': 'not-enough-balance' } )
}

export function hasBegunEventAction( action: 'begin_shipping_info' | 'begin_checkout' ): boolean {
  return getEventQueue().some( ( event ) => event.eventAction === action ) ?? false
}

function track( data: Record<string, any> ): void {
  getEventQueue().push( data )
}

function getEventQueue(): Array<any> {
  if ( !window.dataLayer ) {
    window.dataLayer = []
  }

  return window.dataLayer
}

export const createRegister = track
