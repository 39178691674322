interface Promotion {
  benefits: Promotion.Benefit[]
}
namespace Promotion {
  export interface Codable extends Promotion {
    code: string
  }

  export interface AutoAppliable extends Promotion{
    rules: AutoAppliable.Rule.Group
  }
  export namespace AutoAppliable {
    export interface Rule {
      tipo: string
      [K: string]: string
    }
    export namespace Rule {
      export interface Group {
        and?: Array<Group | Rule>
        or?: Array<Group | Rule>
      }
    }
  }

  export interface Benefit {
    type: Benefit.Type
  }
  export namespace Benefit {
    export const enum Type {
      VARIABLE_ACTION = 'VARIABLE_ACTION',
      ADD_TREAT = 'ADD_TREAT',
      CLUBS = 'CLUBS',
      DISCOUNT = 'DISCOUNT',
      FREE_TRIAL = 'FREE_TRIAL',
      REMOVE_TREAT = 'REMOVE_TREAT',
      DISCOUNTED_SALE = 'DISCOUNTED_SALE',
      VETERANS = 'VETERANS',
    }
    export interface VariableAction extends Benefit{
      type: Type.VARIABLE_ACTION
      product: Product
      validProducts: Product[]
    }

    export interface AddTreat extends Benefit {
      type: Type.ADD_TREAT,
      product: Product
    }

    export interface Clubs extends Benefit {
      type: Type.CLUBS,
      values: {
        curadoria: {
          yearly: number,
          monthly: number,
        },
        ineditos: {
          yearly: number,
          monthly: number,
        }
      }
    }

    export interface Discount extends Benefit {
      type: Type.DISCOUNT
      target: Discount.Target
      percent: boolean
      value: number
    }
    export namespace Discount {
      export const enum Target {
        TOTAL = 'TOTAL',
        KIT = 'KIT',
        FRETE = 'FRETE',
        FRETE_1_KIT = 'FRETE_1_KIT',
        FRETE_TODOS = 'FRETE_TODOS',
      }
    }

    export interface FreeTrial extends Benefit {
      type: Type.FREE_TRIAL
      kit: {
        book: {
          publisher: string,
          title: string,
          author: string,
          curator: string | null,
          pages: number | null,
          picture: string | null,
          synopsis: string,
          releaseDate: string | null,
        }
      }
    }

    export interface RemoveTreat extends Benefit {
      type: Type.REMOVE_TREAT,
      product: Product
    }

    export interface DiscoutedSale extends Benefit {
      type: Type.DISCOUNTED_SALE
    }

    export interface Veterans extends Benefit {
      type: Type.VETERANS
      values: Clubs['values']
    }
  }

  export interface Product {
    id: number
    description: string
    display: string
    price: number | null
    weight: number
    picture: string | null
  }
}

export default Promotion
