import React, { createContext, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

const AssociateContext = createContext()

const AssociateProvider = ( props ) => {
  const {
    cpf = '',
    email = '',
    name = '',
    phone = '',
    token = '',
    utm = {},
  } = props
  const [ state, setState ] = useState( {
    cpf,
    email,
    name,
    phone,
    token,
    utm,
  } )

  const value = useMemo( () => {
    return {
      setState,
      state
    }
  }, [ state ] )

  return (
    <AssociateContext.Provider
      value={ value }
      { ...props }
    />
  )
}


const useAssociate = () => {
  const context = useContext( AssociateContext )
  if ( !context ) {
    throw new Error( 'useAssociate should have be inside a AssociateProvider' )
  }
  const { state, setState } = context

  const setEmail = ( email ) => {
    setState( prevState => ( {
      ...prevState,
      email
    } ) )
  }

  const setName = ( name ) => {
    setState( prevState => ( {
      ...prevState,
      name
    } ) )
  }

  const setCPF = ( cpf ) => {
    setState( prevState => ( {
      ...prevState,
      cpf
    } ) )
  }

  const setPhone = ( phone ) => {
    setState( prevState => ( {
      ...prevState,
      phone
    } ) )
  }

  const setPerson = ( person ) => {
    setCPF( person.cpf )
    setEmail( person.email )
    setName( person.name )
    setPhone( person.phone )
  }

  const setToken = ( token ) => {
    setState( prevState => ( {
      ...prevState,
      token
    } ) )
  }

  const setUtm = ( utm ) => {
    setState( prevState => ( {
      ...prevState,
      utm
    } ) )
  }

  return {
    'cpf': state.cpf,
    'email': state.email,
    'name': state.name,
    'phone': state.phone,
    setCPF,
    setEmail,
    setName,
    setPerson,
    setPhone,
    setToken,
    'token': state.token,
    setUtm,
    'utm': state.utm
  }
}

AssociateProvider.propTypes = {
  'cpf': PropTypes.string.isRequired,
  'email': PropTypes.string.isRequired,
  'name': PropTypes.string.isRequired,
  'phone': PropTypes.string.isRequired,
  'token': PropTypes.string.isRequired
}

export { AssociateProvider, useAssociate }
