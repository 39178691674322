import { MenuProvider, Step } from './MenuContext'
import React, { useEffect } from 'react'
import { AddressProvider } from './AddressContext'
import { AssociateProvider } from './AssociateContext'
import { CategoryProvider } from './CategoryContext'
import { OrderSummaryProvider } from './OrderSummaryContext'
import { PaymentProvider } from './PaymentContext'
import PropTypes from 'prop-types'
import { TagMonthProvider } from './tagMonthContext'
import mixpanel from 'mixpanel-browser'
import useTrackPageview from '../hooks/useTrackPageview'
export { useAddress } from './AddressContext'
export { useMenu } from './MenuContext'
export { useCategory } from './CategoryContext'
export { useTagMonth } from './tagMonthContext'
export { useAssociate } from './AssociateContext'
export { usePayment } from './PaymentContext'
export { useOrderSummary } from './OrderSummaryContext'

const isDebug = process.env.GATSBY_ACTIVE_ENV === 'dev'

export const WrapperProvider = ( { children, associate = {}, address = {}, payment = {}, orderSummary = {}, menu = {} } ) => {
  useEffect( () => {
    mixpanel.init( process.env.GATSBY_MIXPANEL_ID, { 'debug': isDebug } )
  }, [] )

  useTrackPageview()

  return (
    <MenuProvider { ...menu }>
      <AddressProvider { ... address }>
        <AssociateProvider { ...associate }>
          <PaymentProvider { ...payment }>
            <OrderSummaryProvider { ...orderSummary }>
              <TagMonthProvider>
                <CategoryProvider>
                  {children}
                </CategoryProvider>
              </TagMonthProvider>
            </OrderSummaryProvider>
          </PaymentProvider>
        </AssociateProvider>
      </AddressProvider>
    </MenuProvider>
  )
}

WrapperProvider.propTypes = {
  'address': PropTypes.object,
  'associate': PropTypes.object,
  'children': PropTypes.any.isRequired,
  'menu': PropTypes.object,
  'orderSummary': PropTypes.object,
  'payment': PropTypes.object,
}

WrapperProvider.defaultProps = {
  'address': {
    'city': '',
    'complement': '',
    'district': '',
    'ibgeCode': '',
    'id': 0,
    'number': '',
    'shippingFee': 0,
    'state': '',
    'street': '',
    'zipCode': '',
  },
  'associate': {
    'cpf': '',
    'email': '',
    'name': '',
    'phone': '',
    'token': ''
  },
  'menu': {
    'activeStep': Step.CHOOSE_BOX,
    'selectedIndex': Step.CHOOSE_BOX,
    'steps': [
      { 'completed': false, 'href': '/associe-se/escolha-sua-caixinha', 'label': 'Caixinha' },
      { 'completed': false, 'href': '/associe-se/identificacao/', 'label': 'Identificação' },
      { 'completed': false, 'href': '/associe-se/entrega', 'label': 'Entrega' },
      { 'completed': false, 'href': '/associe-se/pagamento', 'label': 'Pagamento' },
      { 'completed': false, 'href': '/associe-se/pix-confirmado', 'label': 'Pix confirmado' },
      { 'completed': false, 'href': '/associe-se/assinatura-concluida', 'label': 'Assinatura concluída' }
    ]
  },
  'orderSummary': {
    'coupon': {
      'benefits': [],
      'code': ''
    },
    'discount': 0,
    'totalAmount': 0,
    'totalShippingFee': 0
  },
  'payment': {
    'cardNumber': '',
    'cvv': '',
    'expirationDate': '',
    'installments': { 'amount': 0, 'month': 12 },
    'nameOnCard': '',
    'notEnoughBalanceErrorCount': 0,
    'paymentError': false,
    'pix': false
  },
}
