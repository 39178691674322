/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState, ReactElement, createContext } from 'react'
import { PreviousKitAnimation } from '@shared/interfaces/PreviousKitAnimation'

export const PreviousKitAnimationContext = createContext<PreviousKitAnimation.Props>( {
  'selectedBookCoverPosition': null,
  'setSelectedBookCoverPosition': () => {},
  'shouldAnimate': false,
  'setShouldAnimate': () => {},
} )

const PreviousKitAnimationProvider = ( {
  'shouldAnimate': shouldAnimateValue = false,
  'selectedBookCoverPosition': selectedBookCoverPositionValue = null,
  children
}: PreviousKitAnimation.Props ): ReactElement => {
  const [ selectedBookCoverPosition, setSelectedBookCoverPosition ] = useState<PreviousKitAnimation.BookCoverPosition | null>( selectedBookCoverPositionValue )
  const [ shouldAnimate, setShouldAnimate ] = useState<boolean>( shouldAnimateValue )

  return (
    <PreviousKitAnimationContext.Provider value={{ selectedBookCoverPosition, setSelectedBookCoverPosition, shouldAnimate, setShouldAnimate }}>
      {children}
    </PreviousKitAnimationContext.Provider>
  )
}

export default PreviousKitAnimationProvider
