export const enum MethodType {
  CREDIT_CARD = 'creditCard',
  PIX = 'pix',
}

export interface MethodPayment {
  TYPE: MethodType
  DESCRIPTION: string
}

export interface MethodDetail {
  methodType: MethodType,
  percentageDiscount: number,
}

export interface CardMethodDetail extends MethodDetail {
  installmentsOptions: number[],
  defaultInstallment: number
}
