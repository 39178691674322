import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'
import { useLocation } from '@reach/router'

const routes = [
  {
    'name': 'Curadoria',
    'path': '/curadoria'
  },
  {
    'name': 'Inéditos',
    'path': '/ineditos'
  },
  {
    'name': 'Kits Passados - Curadoria - Livro',
    'path': '/kits-passados/curadoria/{dynamic}'
  },
  {
    'name': 'Kits Passados - Inéditos - Livro',
    'path': '/kits-passados/ineditos/{dynamic}'
  },
  {
    'name': 'Kits Passados - Curadoria',
    'path': '/kits-passados/curadoria'
  },
  {
    'name': 'Kits Passados - Inéditos',
    'path': '/kits-passados/ineditos'
  },
  {
    'name': 'Kits Passados',
    'path': '/kits-passados'
  },
  {
    'name': 'Escolha da caixinha',
    'path': '/associe-se/escolha-sua-caixinha',
  },
  {
    'name': 'Identificação',
    'path': '/associe-se/identificacao',
  },
  {
    'name': 'Entrega',
    'path': '/associe-se/entrega',
  },
  {
    'name': 'Pagamento',
    'path': '/associe-se/pagamento',
  },
  {
    'name': 'Pix',
    'path': '/associe-se/pix-confirmado',
  },
  {
    'name': 'Concluído',
    'path': '/associe-se/assinatura-concluida',
  },
  {
    'name': 'Home',
    'path': '/'
  },
]

function getEventInfo( location ) {
  const isCheckoutRoute = location.pathname.includes( '/associe-se/' )

  if ( isCheckoutRoute ) {
    return {
      'eventKey': 'Step',
      'eventName': 'Checkout Pageview',
    }
  } else {
    return {
      'eventKey': 'Page',
      'eventName': 'Pageview',
    }
  }
}

export default function useTrackPageview() {
  const location = useLocation()
  const routeOfCurrentLocation = ( route ) => {
    const isDynamic = route.path.includes( '{dynamic}' )

    if ( isDynamic ) {
      const commonPath = route.path.replace( '{dynamic}', '' )
      return location.pathname.includes( commonPath )
    } else {
      return [ route.path, `${route.path}/` ].includes( location.pathname )
    }
  }

  useEffect( () => {
    const { eventName, eventKey } = getEventInfo( location )
    const routeName = routes.find( routeOfCurrentLocation )?.name

    if ( routeName ) {
      const pageviewData = {
        'Host': location.host,
        [ eventKey ]: routeName
      }

      mixpanel.track( eventName, pageviewData )
    }
  }, [ location.pathname ] )
}
