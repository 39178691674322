import './src/fonts/font.css'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { CountdownProvider } from '@taglivros/tag-hooks'
import React from 'react'
import { TAGThemeProvider } from '@taglivros/tag-components'
import { WrapperProvider } from 'checkout/src/contexts'
import BookFilterProvider from '@site/contexts/BookFilterContext'
import PreviousKitAnimationProvider from '@site/contexts/PreviousKitAnimationContext'
import cookie from 'cookie'

export function onRouteUpdate() {
  try {
    const query = new URLSearchParams( window.location.search )
    const partnerCode = query.get( 'partner_code' )

    if ( partnerCode ) {
      const oneDay = 1000 * 60 * 60 * 24
      const tomorrow = new Date( Date.now() + oneDay )

      document.cookie = cookie.serialize( 'partner_code', partnerCode, { 'path': '/', 'expires': tomorrow } )
    }
  } catch ( error ) {
    console.error( error )
  }
}

const GlobalStyles = createGlobalStyle`
  * {
    border: none;
    box-sizing: border-box;
    font-family: 'CircularStd';
    font-size: 100%;
    padding: 0;
    margin: 0;
  }

  body {
    margin: 0;
  }

  ul {
    list-style: none;
  }

  img {
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }

  .branco {
    color: #fff;
  }

  .capitalized {
    text-transform: uppercase;
  }

  .centered-container {
    margin: 0 auto;
    max-width: 1172px;
  }

  .column {
    flex-direction: column;
  }

  .centered {
    justify-content: center;
    align-items: center;
  }

  .flex {
    display: flex;
  }

  .mobile-only {
    @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
      display: none !important;
    }
  }

  .desktop-only {
    @media(max-width: 1024px) {
      display: none !important;
    }
  }

  .grecaptcha-badge {
    display: none !important;
  }
`

export const wrapPageElement = ( { element } ) => (
  <TAGThemeProvider>
    <CountdownProvider>
      <WrapperProvider>
        <BookFilterProvider>
          <PreviousKitAnimationProvider>
            <ThemeProvider theme={{}}>
              <GlobalStyles />
              {element}
            </ThemeProvider>
          </PreviousKitAnimationProvider>
        </BookFilterProvider>
      </WrapperProvider>
    </CountdownProvider>
  </TAGThemeProvider>
)
