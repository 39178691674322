import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import encrypt from 'adyen-cse-js/js/adyen.encrypt.nodom.min.js'
import { onlyNumbers } from '../utils/formatter'

const PaymentContext = createContext()

const PaymentProvider = ( props ) => {
  const {
    cardNumber = '',
    cvv = '',
    expirationDate = '',
    installments = { 'amount': 0, 'month': 12 },
    nameOnCard = '',
    paymentError = null,
    notEnoughBalanceErrorCount = 0,
    paymentMethod = 'creditCard',
    pix = {
      'valido': false,
    },
    hash = '',
  } = props

  const [ state, setState ] = useState( {
    cardNumber,
    cvv,
    expirationDate,
    installments,
    nameOnCard,
    notEnoughBalanceErrorCount,
    paymentError,
    paymentMethod,
    pix,
  } )

  const value = useMemo( () => {
    return {
      setState,
      state
    }
  }, [ state ] )

  return (
    <PaymentContext.Provider
      value={ value }
      { ...props }
    />
  )
}

const usePayment = () => {
  const context = useContext( PaymentContext )
  if ( !context ) {
    throw new Error( 'usePayment should have be inside a PaymentProvider' )
  }
  const { state, setState } = context

  const setCardNumber = ( cardNumber ) => {
    const newCardNumber = onlyNumbers( cardNumber )
    setState( prevState => ( {
      ...prevState,
      'cardNumber': newCardNumber
    } ) )
  }

  const setCvv = ( cvv ) => {
    setState( prevState => ( {
      ...prevState,
      cvv
    } ) )
  }

  const setExpirationDate = ( expirationDate ) => {
    const newExpirationDate = onlyNumbers( expirationDate )
    setState( prevState => ( {
      ...prevState,
      'expirationDate': newExpirationDate
    } ) )
  }

  const setInstallments = ( month, amount ) => {
    const installments = { amount, month }
    setState( prevState => ( {
      ...prevState,
      installments
    } ) )
  }

  const setNameOnCard = ( nameOnCard ) => {
    setState( prevState => ( {
      ...prevState,
      nameOnCard
    } ) )
  }

  const setCreditCard = ( creditCardInfo ) => {
    setCardNumber( creditCardInfo.cardNumber )
    setCvv( creditCardInfo.cvv )
    setExpirationDate( creditCardInfo.expirationDate )
    setNameOnCard( creditCardInfo.nameOnCard )
  }

  const setPaymentError = ( paymentError ) => {
    setState( prevState => ( {
      ...prevState,
      paymentError
    } ) )
  }

  const setPaymentMethod = ( paymentMethod ) => {
    setState( prevState => ( {
      ...prevState,
      paymentMethod
    } ) )
  }

  const setPix = ( pix ) => {
    setState( prevState => ( {
      ...prevState,
      pix
    } ) )
  }

  const setHash = ( hash ) => {
    setState( prevState => ( {
      ...prevState,
      hash
    } ) )
  }

  const setInvalidPix = () => {
    const pix = { 'valido': false }
    setState( prevState => ( {
      ...prevState,
      pix
    } ) )
  }

  const setNotEnoughBalanceErrorCount = ( notEnoughBalanceErrorCount ) => {
    setState( prevState => ( {
      ...prevState,
      notEnoughBalanceErrorCount
    } ) )
  }

  const getToken = ( creditCardInfo ) => {
    const expirationMonth = creditCardInfo.expirationDate.substring( 0, 2 )
    const expirationYear = `20${creditCardInfo.expirationDate.substring( 3 )}`
    const key = process.env.GATSBY_CHAVE_CRIPTO_ADYEN
    const cseInstance = encrypt.createEncryption( key, {} )

    const validationCard = {
      'cvc': creditCardInfo.cvv,
      'expiryMonth': expirationMonth,
      'expiryYear': expirationYear,
      'generationtime': new Date().toISOString(),
      'holderName': creditCardInfo.nameOnCard,
      'number': creditCardInfo.cardNumber
    }

    if ( cseInstance.validate( validationCard ).valid ) {
      setPaymentError( null )
      return cseInstance.encrypt( validationCard )
    } else {
      setPaymentError( 'default' )
      return null
    }
  }

  return {
    'cardNumber': state.cardNumber,
    'cvv': state.cvv,
    'expirationDate': state.expirationDate,
    getToken,
    'installments': state.installments,
    'nameOnCard': state.nameOnCard,
    'notEnoughBalanceErrorCount': state.notEnoughBalanceErrorCount,
    'paymentError': state.paymentError,
    'paymentMethod': state.paymentMethod,
    'pix': state.pix,
    'hash': state.hash,
    setCardNumber,
    setCreditCard,
    setCvv,
    setExpirationDate,
    setInstallments,
    setNameOnCard,
    setNotEnoughBalanceErrorCount,
    setPaymentError,
    setPaymentMethod,
    setPix,
    setInvalidPix,
    setHash,
  }
}

PaymentProvider.propTypes = {
  'cardNumber': PropTypes.string.isRequired,
  'cvv': PropTypes.string.isRequired,
  'expirationDate': PropTypes.string.isRequired,
  'installments': PropTypes.object.isRequired,
  'nameOnCard': PropTypes.string.isRequired,
  'notEnoughBalanceErrorCount': PropTypes.number.isRequired,
  'paymentError': PropTypes.bool.isRequired,
  'pix': PropTypes.object.isRequired
}

export { PaymentProvider, usePayment }
