/* eslint-disable max-depth */
/* eslint-disable max-lines */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useAddress, useCategory, usePayment } from '.'
import PropTypes from 'prop-types'
import { formatPrice } from '../utils/formatter'
import { calculate } from '@checkout/utils/discount'
import { MethodType } from '@shared/interfaces/payment/methodPayments'
import Promotion from '@shared/interfaces/Promotion'
import { Coupon } from '@shared/interfaces/Coupon'

const OrderSummaryContext = (
  createContext<OrderSummaryContext.Props>( {} as any )
)
namespace OrderSummaryContext {
  export interface State {
    coupon: Coupon,
    couponDescription: string[],
    discount: number,
    totalAmount: number,
    totalShippingFee: number,
    fullPrice: number,
    activePix: boolean,
    promotions: {
      [ MethodType.PIX ]: Promotions,
      [ MethodType.CREDIT_CARD ]: Promotions,
    },
  }

  export interface Promotions {
    coupon: Promotion.Codable[]
    recommendation: Promotion.Codable[]
    autoAppliable: Promotion.AutoAppliable[]
  }

  export interface Props {
    state: State
    setState: React.Dispatch<React.SetStateAction<State>>
  }
}

const OrderSummaryProvider = ( props: Partial<OrderSummaryContext.State> ) => {
  const {
    coupon = {
      'benefits': [],
      'code': '',
      'isRecommendation': false,
    },
    couponDescription = [],
    discount = 0,
    totalAmount = 0,
    totalShippingFee = 0,
    fullPrice = 0,
    activePix = false,
    promotions = {
      [ MethodType.PIX ]: {
        'coupon': [],
        'recommendation': [],
        'autoAppliable': [],
      },
      [ MethodType.CREDIT_CARD ]: {
        'coupon': [],
        'recommendation': [],
        'autoAppliable': [],
      },
    },
  } = props

  const [ state, setState ] = useState( {
    coupon,
    couponDescription,
    discount,
    totalAmount,
    totalShippingFee,
    fullPrice,
    activePix,
    promotions,
  } )

  const value = useMemo( () => {
    return {
      setState,
      state
    }
  }, [ state ] )

  return (
    <OrderSummaryContext.Provider
      value={ value }
      { ...props }
    />
  )
}

const useOrderSummary = () => {
  const context = useContext( OrderSummaryContext )

  if ( !context ) {
    throw new Error( 'useOrderSummary should be inside a OrderSummaryProvider' )
  }

  const { state, setState } = context

  const { activeCategory, activePlan, findPlanById, findPlan } = useCategory()
  const { installments, setInstallments, paymentMethod } = usePayment()
  const { shippingFee } = useAddress()

  useEffect( setTotalAmount, [ state.promotions ] )

  const yearlyPlan = findPlan( activeCategory, 'anual' )

  function getTotalShippingFee() {
    return activePlan.tipo_plano === 'Anual' ? ( 12 * shippingFee ) : shippingFee
  }

  function getTotalAmountYearly() {
    const totalShippingFee = getTotalShippingFee() * 12
    const totalKitsPrice = yearlyPlan.valor_total

    const paymentMethodDiscount = getPaymentMethodDiscount( paymentMethod )
    const kitsDiscount = totalKitsPrice * paymentMethodDiscount.kits.percent / 100
    const shippingDiscount = totalShippingFee * paymentMethodDiscount.shipping.percent / 100

    return totalKitsPrice + ( 12 * shippingFee ) - kitsDiscount - shippingDiscount
  }

  function getDiscount( coupon: Coupon, plan = activePlan ) {
    return calculate( coupon, plan, shippingFee )
  }

  function getPaymentMethodDiscount( paymentMethod: MethodType ) {
    function hasPaymentMethodRule( rules: Promotion.AutoAppliable.Rule.Group ) {
      const keys = Object.keys( rules )

      if ( !keys.length ) {
        return false
      }

      if ( keys.includes( 'and' ) || keys.includes( 'or' ) ) {
        for ( const key of keys ) {
          const hasPaymentMethod = hasPaymentMethodRule( ( rules as any )[ key ] )

          if ( hasPaymentMethod ) {
            return true
          }
        }

        return false
      } else {
        const methodMap = {
          [ MethodType.PIX ]: 'PIX',
          [ MethodType.CREDIT_CARD ]: 'Cartão de Crédito',
        }

        for ( const rule of rules as Promotion.AutoAppliable.Rule[] ) {
          if ( rule[ 'forma_pagamento' ] === methodMap[ paymentMethod ] ) {
            return true
          }
        }

        return false
      }
    }

    const discounts = {
      'kits': {
        'percent': 0,
      },
      'shipping': {
        'percent': 0,
      },
    }

    for ( const promotion of state.promotions[ paymentMethod ].autoAppliable ) {
      if ( hasPaymentMethodRule( promotion.rules ) ) {
        for ( const benefit of promotion.benefits ) {
          if ( benefit.type === Promotion.Benefit.Type.DISCOUNT ) {
            if ( ( benefit as Promotion.Benefit.Discount ).target === 'TOTAL' ) {
              discounts.kits.percent += ( benefit as Promotion.Benefit.Discount ).value
            } else if ( ( benefit as Promotion.Benefit.Discount ).target === 'FRETE_TODOS' ) {
              discounts.shipping.percent += ( benefit as Promotion.Benefit.Discount ).value
            }
          }
        }
      }
    }

    return discounts
  }

  function setPromotions( paymentMethod: MethodType, promotions: Required<OrderSummaryContext.Promotions> ) {
    setState( prevState => ( {
      ...prevState,
      'promotions': {
        ...state.promotions,
        [ paymentMethod ]: promotions,
      }
    } ) )
  }

  function limitShippingDiscount( shippingDiscount: number, couponDiscount: number, coupon?: Coupon ) {
    if ( !shippingDiscount || !couponDiscount ) {
      return shippingDiscount || couponDiscount
    }

    if ( !coupon?.benefits[ 0 ]?.tipo_desconto.codigo.startsWith( 'FRETE' ) ) {
      return shippingDiscount + couponDiscount
    }

    return Math.min( shippingDiscount + couponDiscount, getTotalShippingFee() )
  }

  function setTotalAmount( coupon = state.coupon, plan = activePlan ) {
    const totalShippingFee = getTotalShippingFee()
    const totalKitsPrice = plan.valor_total

    const paymentMethodDiscount = getPaymentMethodDiscount( paymentMethod )
    const kitsDiscount = totalKitsPrice * paymentMethodDiscount.kits.percent / 100
    const shippingDiscount = totalShippingFee * paymentMethodDiscount.shipping.percent / 100
    const couponDiscount = getDiscount( coupon )
    const limitedShippingDiscount = limitShippingDiscount( shippingDiscount, couponDiscount, coupon )

    const totalAmount = totalKitsPrice + totalShippingFee - limitedShippingDiscount - kitsDiscount

    setInstallments( installments.month, totalAmount / installments.month )

    setState( prevState => ( {
      ...prevState,
      totalAmount,
    } ) )
  }

  function setTotalShippingFee() {
    setState( prevState => ( {
      ...prevState,
      'totalShippingFee': getTotalShippingFee(),
    } ) )
  }

  function getFullPrice() {
    const monthlyPlan = findPlanById( activeCategory, activeCategory.planos[ 1 ].id )
    const monthlyPlanYearlyAmount = monthlyPlan.valor_por_kit * 12
    const shippingFeeYearlyAmount = shippingFee * 12
    const fullPrice = monthlyPlanYearlyAmount + shippingFeeYearlyAmount

    return fullPrice
  }

  function setFullPrice() {
    setState( prevState => ( {
      ...prevState,
      'fullPrice': getFullPrice(),
    } ) )
  }

  function setCouponDescription( coupon: Coupon, plan = activePlan ) {
    const description = Array<string>()
    const discount = getDiscount( coupon, plan )
    const { isRecommendation, benefits } = coupon

    const isTrial = benefits.find( ( benefit ) => benefit.tipo === 'Free Trial' )
    const isVariableAction = benefits[ 0 ]?.tipo === 'Ação variável'
    const isDiscount = !isTrial && benefits[ 0 ]?.tipo === 'Desconto'

    if ( isRecommendation ) {
      description.push( `-${formatPrice( coupon.discount )} (30% do valor do primeiro kit)` )
    } else if ( isTrial ) {
      const kit = plan.categoria === 'Curadoria' ? '' : 'O Beijo do Rio'
      description.push( `${kit} - Primeiro Kit ${formatPrice( plan.valor_por_kit - discount )}` )
    } else if ( coupon.customDescription ) {
      description.push( coupon.customDescription )
    } else if ( isVariableAction ) {
      benefits.forEach( ( benefit ) => {
        description.push( benefit.produto.exibicao )
      } )
    } else if ( isDiscount ) {
      const isPercentage = benefits[ 0 ]!.percentual
      const discountPrice = discount > 0 ? formatPrice( discount ) : ''
      const benefitDescription = benefits[ 0 ]!.tipo_desconto.codigo === 'KIT' ? 'Kit' : 'Frete'

      if ( isPercentage ) {
        description.push( `-${discountPrice} (${benefits[ 0 ]!.valor}% ${benefitDescription})` )
      } else {
        description.push( `-${discountPrice}` )
      }
    } else {
      description.push( '' )
    }

    setState( prevState => ( {
      ...prevState,
      'couponDescription': description,
    } ) )
  }

  function setCoupon( coupon: Coupon, plan = activePlan ) {
    const reorderedBenefits = coupon.benefits.reduce( ( acc, benefit ) => {
      if ( benefit.tipo === 'Desconto' ) {
        return [ benefit, ...acc ]
      }
      return [ ...acc, benefit ]
    }, Array<Coupon.Promotion.Benefit>() )

    const reorderedCoupon = { ...coupon, 'benefits': reorderedBenefits }
    const discount = getDiscount( reorderedCoupon, plan )

    setState( prevState => ( {
      ...prevState,
      'coupon': reorderedCoupon,
      discount,
    } ) )

    if ( typeof discount === 'number' ) {
      setTotalAmount( reorderedCoupon, plan )
      setCouponDescription( reorderedCoupon, plan )
    }
  }

  function setActivePix() {
    setState( prevState => ( {
      ...prevState,
      'activePix': true
    } ) )
  }

  useEffect( () => {
    setCoupon( state.coupon )
  }, [ shippingFee ] )

  return {
    'activePix': state.activePix,
    'coupon': state.coupon,
    'couponDescription': state.couponDescription,
    'discount': state.discount,
    'promotions': state.promotions,
    setActivePix,
    setCoupon,
    setCouponDescription,
    setTotalAmount,
    setTotalShippingFee,
    setFullPrice,
    getTotalAmountYearly,
    setPromotions,
    getPaymentMethodDiscount,
    'totalAmount': state.totalAmount,
    'fullPrice': state.fullPrice,
  }
}

OrderSummaryProvider.propTypes = {
  'coupon': PropTypes.object.isRequired,
  'couponDescription': PropTypes.array.isRequired,
  'discount': PropTypes.number.isRequired,
  'fullPrice': PropTypes.number.isRequired,
  'totalAmount': PropTypes.number.isRequired,
  'totalShippingFee': PropTypes.number.isRequired,
}

export { OrderSummaryProvider, useOrderSummary }
